import React, { useState } from 'react';
import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import { StaticImage } from 'gatsby-plugin-image';
import FsLightbox from 'fslightbox-react';
import MainImage1 from '../../images/tad/tad_mainshop_1.jpg';
import MainImage2 from '../../images/tad/tad_mainshop_2.jpeg';
import SouvenirImage1 from '../../images/tad/tad_suv_1.jpeg';
import SouvenirImage2 from '../../images/tad/tad_suv_2.jpeg';
import VipShopImage from '../../images/tad/tad_vip_1.jpeg';
import VipShopImage2 from '../../images/tad/tad_vip_2.jpg';
import CipShopImage from '../../images/tad/tad_cip_1.png';
import CipShopImage2 from '../../images/tad/tad_cip_2.jpeg';
import { Container } from 'react-bootstrap';
import TadContent from './TadContent';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

const Wrapper = styled.div`
  font-family: 'Open Sans', sans-serif;
  color: var(--tad-green);
  img {
    overflow: hidden;
  }
`;

const Title = styled.h1`
  font-size: 1.525rem;
  text-transform: uppercase;
  margin: 1rem 0;
  text-align: left;
  font-weight: bold;
  text-align: left;
  margin-top: 3rem;
  @media (max-width: 768px) {
    margin-top: 1rem;
  }
`;

const Text = styled.p`
  margin: 1.325rem 0;
  text-align: left;
`;

const TextContent = styled.div`
  text-align: left;
  padding: 1rem 2rem 1rem 2rem;
  @media (max-width: 768px) {
    padding: 1rem 1rem 1rem 1rem;
  }
`;

const BoxTitle = styled.h3`
  font-size: 1rem;
  text-align: center;
  margin: 1.325rem 0;
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover {
    opacity: 0.9;
    box-shadow: rgba(100, 100, 111, 0.5) 0px 7px 29px 0px;
  }
`;

const Intro = styled.p`
  font-size: 1.125rem;
  margin: 0 auto;
  margin-top: 2rem;
  text-align: center;
  color: black;
  margin-bottom: 4rem;
  @media (max-width: 768px) {
    font-size: 1rem;
    text-align: left;
  }
`;

const ImagesContainer = styled.div`
  display: block;
  background: var(--tad-green);
  color: white;
  margin: 2rem 0;
  width: 100%;
  padding: 4rem;
  @media (max-width: 768px) {
    padding: 2rem 1rem;
  }
`;

const MainTitle = styled.h2`
  font-size: 2.325rem;
  margin: 2rem 0;
  text-align: center;
  font-weight: bold;
  @media (max-width: 768px) {
    font-size: 1.525rem;
  }
`;

const TadContentShops = () => {
  const [toggler, setToggler] = useState(false);
  const breakpoints = useBreakpoint();
  return (
    <TadContent selectedMenu={'Shops'}>
      <Container>
        <Container className={breakpoints.sm ? 'p-0' : ''}>
          <Wrapper>
            <Row>
              <Col>
                <Intro className="animate__animated animate__fadeIn">
                  Embark on a captivating shopping adventure as you explore our four uniquely designed shops,
                  collectively spanning an impressive total area of 395 square meters. Within these spaces, we proudly
                  offer an extensive and diverse range of product categories, featuring approximately 1000 carefully
                  selected products designed to cater to every taste and need. From the latest in high-tech gadgets and
                  electronics to luxurious beauty products, exclusive fashion finds, and gourmet delights, our
                  meticulously curated selection promises something special for everyone. Each shop boasts its own
                  distinct atmosphere and specialized offerings, ensuring a varied and enriching shopping experience as
                  you journey from one to the next. Dive into the world of endless possibilities and discover the
                  perfect items to complement your lifestyle, enhance your travels, or gift to someone special. Our
                  commitment to variety, quality, and exceptional customer service makes each visit a new adventure,
                  inviting you to uncover treasures within our expansive and vibrant retail landscape.
                </Intro>
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={6}>
                <TextContent className="animate__animated animate__fadeInUp">
                  <Title>Indulge in Luxury: Premier Alcoholic Beverages</Title>
                  <Text>
                    Step into a world where the essence of refinement meets the spirit of celebration. At TAD Duty Free,
                    we take pride in presenting an exquisite collection of premium alcoholic beverages. From the deep,
                    rich flavors of meticulously aged whiskies to the effervescent elegance of fine champagnes and the
                    nuanced bouquets of select wines, our curated selection is designed to enchant connoisseurs and
                    casual enthusiasts alike.
                  </Text>
                </TextContent>
              </Col>
              <Col sm={12} md={6}>
                <TextContent className="animate__animated animate__fadeInUp">
                  <Title>Elevate Your Essence: Cosmetics and Perfumes</Title>
                  <Text>
                    Enhance your natural allure with our lavish assortment of cosmetics and perfumes. At TAD Duty Free,
                    elegance is within reach through an elite selection of beauty products and fragrances. Sourced from
                    the world’s most prestigious brands, each item promises to elevate your daily ritual, leaving a
                    lasting impression wherever you go.
                  </Text>
                </TextContent>
              </Col>
            </Row>
            <Row>
              <Col>
                <TextContent>
                  <Title>Discover Unique Treasures: Locally Crafted Souvenirs</Title>
                  <Text>
                    Immerse yourself in the artistry and charm of our destination with TAD Duty Free’s exclusive range
                    of locally crafted souvenirs. Each piece, from one-of-a-kind jewelry that whispers tales of
                    tradition, to handcrafted keychains, charms, and cosmetics, embodies the unique spirit and
                    craftsmanship of our locale. Bring home a piece of your journey with these distinctive keepsakes
                    that promise more than just memories.
                  </Text>
                </TextContent>
              </Col>
              <Col>
                <TextContent>
                  <Title>Savor the Delight: Assortment of Sweets and Local Specialties</Title>
                  <Text>
                    Embark on a flavorful journey with TAD Duty Free’s diverse collection of sweets and local
                    specialties. From the irresistible charm of artisan chocolates and candies to the wholesome goodness
                    of special honey, seeds, and dried fruits, our offerings are a testament to the rich culinary
                    heritage and bountiful harvests of our region. Delight your senses and take a piece of this
                    sweetness with you, as a treat for yourself or a cherished gift for someone special.
                  </Text>
                </TextContent>
              </Col>
            </Row>
          </Wrapper>
        </Container>
      </Container>
      <ImagesContainer>
        <Container className={breakpoints.sm ? 'p-0' : ''}>
          <Row>
            <MainTitle>International Airport Dushanbe</MainTitle>
          </Row>
          <Row>
            <Col className="p-4" xs={12} md={6}>
              <Row className="mb-5">
                <BoxTitle>DEPARTURE (MAIN) SHOP</BoxTitle>
                <FsLightbox
                  toggler={toggler}
                  sources={[
                    MainImage1,
                    MainImage2,
                    SouvenirImage1,
                    SouvenirImage2,
                    VipShopImage,
                    VipShopImage2,
                    CipShopImage,
                    CipShopImage2,
                  ]}
                />
                <Col>
                  <ImageContainer>
                    <StaticImage
                      onClick={() => setToggler(!toggler)}
                      width={300}
                      src="../../images/tad/tad_mainshop_1.jpg"
                      alt="Main Shop"
                      placeholder="blurred"
                    />
                  </ImageContainer>
                </Col>
                <Col>
                  <ImageContainer>
                    <StaticImage
                      onClick={() => setToggler(!toggler)}
                      width={300}
                      src="../../images/tad/tad_mainshop_2.jpeg"
                      alt="Main Shop"
                      placeholder="blurred"
                    />
                  </ImageContainer>
                </Col>
              </Row>

              <Row>
                <BoxTitle>SOUVENIR SHOP</BoxTitle>
                <Col>
                  <ImageContainer>
                    <StaticImage
                      onClick={() => setToggler(!toggler)}
                      width={300}
                      src="../../images/tad/tad_suv_1.jpeg"
                      alt="Main Shop"
                      placeholder="blurred"
                    />
                  </ImageContainer>
                </Col>
                <Col>
                  <ImageContainer>
                    <StaticImage
                      onClick={() => setToggler(!toggler)}
                      width={300}
                      src="../../images/tad/tad_suv_2.jpeg"
                      alt="Main Shop"
                      placeholder="blurred"
                    />
                  </ImageContainer>
                </Col>
              </Row>
            </Col>
            <Col className="p-4">
              <Row className="mb-5">
                <BoxTitle>VIP SHOP</BoxTitle>
                <Col>
                  <ImageContainer>
                    <StaticImage
                      onClick={() => setToggler(!toggler)}
                      width={300}
                      src="../../images/tad/tad_vip_1.jpeg"
                      alt="Main Shop"
                      placeholder="blurred"
                    />
                  </ImageContainer>
                </Col>
                <Col>
                  <ImageContainer>
                    <StaticImage
                      onClick={() => setToggler(!toggler)}
                      width={300}
                      src="../../images/tad/tad_vip_2.jpg"
                      alt="Main Shop"
                      placeholder="blurred"
                    />
                  </ImageContainer>
                </Col>
              </Row>
              <Row>
                <BoxTitle>CIP SHOP</BoxTitle>
                <Col>
                  <ImageContainer>
                    <StaticImage
                      onClick={() => setToggler(!toggler)}
                      width={300}
                      src="../../images/tad/tad_cip_1.png"
                      alt="Main Shop"
                      placeholder="blurred"
                    />
                  </ImageContainer>
                </Col>
                <Col>
                  <ImageContainer>
                    <StaticImage
                      onClick={() => setToggler(!toggler)}
                      width={300}
                      src="../../images/tad/tad_cip_2.jpeg"
                      alt="Main Shop"
                      placeholder="blurred"
                    />
                  </ImageContainer>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </ImagesContainer>
    </TadContent>
  );
};

export default TadContentShops;
